<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item" v-if="type != 'share_corona_0' && type != 'join_group_corona_0'">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs-sv3')"
                    id="log-sv3-tab"
                    data-toggle="tab"
                    href="#log-sv3"
                    role="tab"
                    aria-controls="log-sv3"
                    aria-selected="false"
                    >Nhật ký Order SV3</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div v-if="type_default == 'like_page_corona' && !checkedId" class="col-md-8">
                        <div class="card card-orange mt-2">
                            <div class="card-body py-2">
                                <h6 class="bold font-18 text-center red">
                                    Hướng dẫn kiểm tra ID Fanapge trước khi tăng Like
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 1:</span> Lấy ID Fanapage và nhập vào ô Input đầu tiên
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 2:</span> Lấy 1 tài khoản Facebook bất kỳ và Like trang
                                    Fanpage.
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 3:</span> Lấy ID tài khoản Facebook vừa Like Fanpage và nhập
                                    vào ô Input thứ 2.
                                </h6>
                                <h6 class="font-14">
                                    <span class="bold">Bước 4:</span> Click Kiểm Tra để kiểm tra Fanpage có thể tăng
                                    Like hay không.
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group mt-3">
                            <div class="col-md-4 col-12">
                                <h6 class="bold mb-0">
                                    ID
                                    {{
                                        type_default == "followe_corona"
                                            ? "trang cá nhân"
                                            : type_default == "like_page_corona"
                                            ? "Fanpage"
                                            : "bài viết"
                                    }}
                                    cần tăng Like:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="object_id" type="text" class="form-control input-light" />
                                <div v-if="type_default !== 'like_page_corona'" class="card card-orange mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group-lg form-group">
                            <div class="col-md-4 col-12">
                                <h6 class="bold mb-0">ID Facebook đã Like Page:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="facebook_id" type="text" class="form-control input-light" />
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <button
                                            @click="checkIDPage()"
                                            class="btn btn-block btn-dark-blue btn-rounded bold mt-3"
                                            type="submit"
                                        >
                                            Kiểm tra
                                        </button>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <button
                                            @click="checkedId = !checkedId"
                                            class="btn btn-block btn-orange btn-rounded bold mt-3"
                                            type="submit"
                                        >
                                            Bỏ qua
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-md-8">
                        <div class="form-group">
                            <div class="card card-orange mb-3">
                                <div class="card-body py-2 text-left">
                                    <h6 class="font-14 green">
                                        Gói Corona là gói <span class="text-danger bold">(KHÔNG BẢO HÀNH)</span> hỗ trợ
                                        các bạn trong thời gian thị trường bị phá giá để có thể cạnh tranh với các Web
                                        khác. Khi thị trường ổn định, hệ thống sẽ đóng gói hỗ trợ này để cân bằng với
                                        giá thị trường. Hệ thống tăng tối đa
                                        <span class="text-danger bold">50.000</span> đối với gói Corona.
                                        <!-- Các gói khác hệ thống sẽ Reset vào <span class="text-danger bold">(12H TRƯA MỖI NGÀY)</span> -->
                                    </h6>
                                    <div v-if="counter_today && max_today">
                                        <hr class="bg-danger my-2" />
                                        <h6
                                            v-for="(item, index) in type_count"
                                            :key="index"
                                            class="mb-0 text-danger text-uppercase bold text-center"
                                        >
                                            Số lượng {{ (type_default + item).toUpperCase() }} đã tăng trong ngày:
                                            {{ counter_today[type_default + item] | formatNumber }}/{{
                                                max_today[type_default + item] | formatNumber
                                            }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Link hoặc ID
                                    {{
                                        type_default == "followe_corona"
                                            ? "trang cá nhân"
                                            : type_default == "like_page_corona"
                                            ? "Fanpage"
                                            : "bài viết"
                                    }}:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div
                                    v-if="
                                        type_default == 'like_page_corona' ||
                                            type_default == 'follow_corona' ||
                                            type_default == 'review'
                                    "
                                    class="card card-orange mt-2"
                                >
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://2uid.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >2uid.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Loại {{ type_default.replace("_corona", "").toUpperCase() }} cần Buff:</h6>
                            </div>
                            <div class="col-md col-12">
                                <select v-model="type_corona" class="custom-select select-light">
                                    <option value="0" selected>Buff {{ type_default.replace("_corona", "").toUpperCase() }} Max - Buff Không Giới Hạn {{ type_default.replace("_corona", "").toUpperCase() }}</option>
                                    <option v-if="type_default != 'like_corona'" value="">Buff {{ type_default.replace("_corona", "").toUpperCase() }} 3K - Tối đa mỗi ID 3.000 {{ type_default.replace("_corona", "").toUpperCase() }}</option>
                                    <option v-if="type_default != 'like_corona'" value="5">Buff {{ type_default.replace("_corona", "").toUpperCase() }} 5K - Tối đa mỗi ID 5.000 {{ type_default.replace("_corona", "").toUpperCase() }}</option>
                                    <option v-if="type_default != 'like_corona'" value="10">Buff {{ type_default.replace("_corona", "").toUpperCase() }} 10K - Tối đa mỗi ID 10.000 {{ type_default.replace("_corona", "").toUpperCase() }}</option>
                                </select>
                            </div>
                        </div> -->
                        <div v-if="type_default != 'comment' && type_default != 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="quantity" type="number" class="form-control input-light" />
                                <div v-if="type_default != 'view'" class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 text-danger font-bold">
                                            Lưu ý: hệ thống hoạt động theo hình thức order tức là bạn order số lượng bao
                                            nhiêu thì hệ thống sẽ tự động lấy trong cơ sở dữ liệu ra số lượng người
                                            tương ứng để like, follow,... cho bạn.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold">
                                            Nên nếu nick của họ bị khóa (checkpoint) trong lúc tăng sẽ gây tụt trong lúc
                                            buff làm lượng like lên không đủ số lượng cần tăng là bình thường.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold mb-0">
                                            Do đó, vui lòng buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt hoặc
                                            chọn gói bảo hành để được hoàn tiền nếu tụt. Chúng tôi sẽ không bảo hành với
                                            bất cứ lý do nào.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Server:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white mt-2">
                                    <div class="card-body p-2 ">
                                        <label
                                            v-if="type != 'share_corona_0' && type != 'join_group_corona_0'"
                                            for="corona"
                                            class="mb-1 d-flex align-items-center cursor-pointer"
                                            ><input
                                                type="radio"
                                                name="server"
                                                value="corona"
                                                id="corona"
                                                v-model="server"
                                                class="cursor-pointer"
                                            /><span class="ml-2"
                                                >Tăng <b class="text-danger font-bold mr-2">{{ textCorona }}</b>
                                                <span class="btn btn-info py-0 px-1 ">
                                                    {{ priceCorona }} {{ currency }}</span
                                                >
                                                (chất lượng cao: người việt, người thật - click bằng tay, tốc độ trung
                                                bình, tỉ lệ tụt thấp)</span
                                            ></label
                                        >
                                        <!-- <label
                                            v-if="type != 'comment'"
                                            for="sv3"
                                            class="mb-1 d-flex align-items-center cursor-pointer"
                                            ><input
                                                type="radio"
                                                name="server"
                                                id="sv3"
                                                value="sv3"
                                                v-model="server"
                                                class="cursor-pointer"
                                            /><span class="ml-2"
                                                >Tăng
                                                <span class="text-danger font-bold mr-2"> {{ textSv3 }}</span>
                                                <span class="btn btn-info py-0 px-1">
                                                    {{ sv3Price }} {{ currency }}</span
                                                >
                                                (clone nuôi, tốc độ nhanh, không bảo hành)</span
                                            ></label
                                        > -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row form-group" v-if="server === 'sv3'">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Tốc độ:</h6>
                            </div>
                            <facebookSv3SpeedVue :speeds="sv3SpeedsArray" @updateSpeedSv3="updateChosenSpeed" />
                        </div> -->

                        <div v-if="type_default != 'view'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ price_default }} {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control input-light"
                                />
                                <div class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 mb-0 text-justify">
                                            Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn
                                            có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                            nhé.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="type_default === 'comment' || type_default === 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Nội dung cần comment:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="text-danger font-bold font-13">
                                            Nghiêm cấm {{ type_default == "comment" ? "bình luận" : "Review" }} các nội
                                            dung có biểu tượng Icon.
                                        </h6>
                                        <h6 class="text-danger font-bold font-13">
                                            Nghiêm cấm {{ type_default == "comment" ? "bình luận" : "Review" }} những
                                            nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu ghẹo, xúc phạm nhân phẩm,
                                            danh dự của Cá nhân hoặc Tổ chức.
                                        </h6>
                                        <h6 class="text-danger font-bold font-13">
                                            Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                            phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                        </h6>
                                        <h6 v-if="type_default == 'review'" class="text-danger bold font-13">
                                            Hãy chắc chắn Fanpage được buff Review đã mở phần đánh giá, nếu Fanpage chưa
                                            mở đánh giá và bạn cố tình cho vào hệ thống để buff sẽ không được hoàn tiền.
                                        </h6>
                                        <h6 class="text-danger font-bold font-13 mb-0">
                                            Các ngôn từ bị cấm: dm|đm|đ m|d m|địt mẹ|dit
                                            me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua
                                            dao|con cho|tra tien|mat day
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <label class="mr-2 mt-3"
                                        >Nhập nội dung {{ type_default == "comment" ? "bình luận" : "Review" }} (<label
                                            class="font-bold"
                                            >Mỗi dòng tương đương với 1
                                            {{ type_default == "comment" ? "bình luận" : "Review" }}</label
                                        >):</label
                                    >
                                    <span class="badge badge-primary text-white py-1">Số lượng: {{ quantity }}</span>
                                    <h6 v-if="type_default == 'review'">
                                        <span class="badge badge-danger text-white py-2"
                                            >Lưu ý: Nội dung của một review tối thiểu phải 25 ký tự</span
                                        >
                                    </h6>
                                    <textarea
                                        class="form-control input-light"
                                        rows="10"
                                        v-model="list_messages"
                                        placeholder="Nhập nội dung seeding, mỗi dòng 1 tương ứng 1 nội dung"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff <span class="bold">{{ quantity }} {{ typeText }}</span> với giá
                                            <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / {{ typeText }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="type_default != 'view'" class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Thời gian làm việc (tăng seeding) và bảo hành tính từ ngày bắt đầu cho đến ngày
                                    kết thúc job, tối đa là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng hệ thống sẽ tự động hoàn lại
                                    số tiền seeding chưa tăng cho bạn <b class="font-bold">trong vòng 1 đến 3 ngày</b>
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id bài viết, công khai và check kỹ job tránh tạo nhầm, tính năng
                                    đang trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p v-if="type_default == 'like_page_corona'" class="font-bold">
                                    - Không tăng Like cho Page có thời gian tạo dưới 1 tháng.
                                </p>
                                <p v-else>
                                    - Chỉ nhận id bài viết công khai không nhập id album, id comment, id trang cá nhân,
                                    id page,...
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                                <p>
                                    - Mỗi id có thể
                                    <span class="font-bold">Buff tối đa 10 lần trên hệ thống để tránh Spam</span>, max
                                    1k trong ngày (hoặc hơn nếu order giá cao), trên 1k thời gian lên chậm hơn trong
                                    vòng 7 ngày
                                </p>
                            </div>
                        </div>
                        <div v-else class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <strong
                                    ><b><i class="mr-1"></i>Hướng dẫn: </b></strong
                                >
                                <br />- Số lượng view tối thiểu mỗi lần mua là 500 view , tối đa là 100,000 View.
                                <br />- Hệ thống tăng View và hỗ trợ tăng mắt LiveStream trong quá trình chạy View.
                                <br />- Để giống người xem thật, số lượng View sẽ được điều chỉnh lên xuống ngẫu nhiên
                                quanh số View của bạn mua.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <facebooklogs :panel="panel" :type="type"></facebooklogs>
            </div>
            <!-- <div class="tab-pane fade" id="log-sv3" role="tabpanel" aria-labelledby="log-sv3-tab">
                <facebookLogsSv3Vue :panel="panel" :type="sv3Type"></facebookLogsSv3Vue>
            </div> -->
        </div>
    </div>
</template>
<script>
import { facebookPostUrlToId, getUserIdFacebookFromUrl, catchError } from "../../../../helpers"
import {
    convertUsernameToFbId,
    orderSeedingCorona,
    checkLikePage,
    getCoronaInfo
} from "../../../../api/services/facebook"
import * as sv3Api from "../../../../api/services/facebook-sv3"
import facebooklogs from "./facebook-logs"
// import facebookSv3SpeedVue from "./facebook-sv3-speed.vue"
// import facebookLogsSv3Vue from "./facebook-logs-sv3.vue"

export default {
    name: "facebook-order",
    components: { facebooklogs },
    data() {
        return {
            facebook_id: "",
            checkedId: true,
            object_id: "",
            type: "",
            type_default: "",
            type_corona: "",
            is_warranty: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            time_expired: "",
            date_warranty: 7,
            gender: "",
            age: {
                min: 0,
                max: 0
            },
            friend: {
                min: 0,
                max: 0
            },
            price: 0,
            is_filter: false,
            panel: "order",
            quantity: 100,
            price_target: 0,
            price_default: 100,
            notes: "",
            list_messages: "",
            counter_today: null,
            max_today: null,
            type_count: ["", "_0", "_5", "_10"],
            server: "corona",
            sv3Speed: "",
            priceCorona: 0,
            sv3Price: 0
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        configSite() {
            return this.$store.state.site.site.config || false
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            let getPackage = "seeding_by_workers_query_config"
            if (this.type === "follow" || this.type === "like_page_corona") {
                getPackage = "seeding_by_workers_query_" + this.type + "_config"
            }
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            if (this.is_filter) {
                let priceTarget = tool.prices.find(price => price.package_name.includes(getPackage))
                let priceTargets = priceTarget ? priceTarget.price : 50
                if (this.type === "follow" || this.type === "like_page_corona") {
                    priceTargets = priceTargets < 100 ? 100 : priceTargets
                } else {
                    priceTargets = priceTargets < 20 ? 20 : priceTargets
                }
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.price_target = priceTargets
                if (this.gender.toString() !== "") {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
                if (this.age.min > 0) {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
                if (this.friend.min > 0) {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
            }
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity
        },
        router() {
            return this.$route.path
        },
        typeText() {
            return this.type.replace(/_10|_5|_0/g, "")
        },
        sv3Type() {
            const { type } = this
            switch (type) {
                case "like_corona_0":
                    return "like_sv3"
                case "like_page_corona_0":
                    return "like_page_sv3"
                case "follow_corona_0":
                    return "follow_sv3"
                case "share_corona_0":
                    return "share_sv3"
                case "join_group_corona_0":
                    return "join_group_sv3"
                default:
                    return type
            }
        },
        textCorona() {
            const { type } = this
            switch (type) {
                case "like_corona_0":
                    return "Like Sv Corona"
                case "like_page_corona_0":
                    return "Like Page Sv Corona"
                case "follow_corona_0":
                    return "Follow Sv Corona"
                case "share_corona_0":
                    return "Share Sv Corona"
                case "join_group_corona_0":
                    return "Join Group Sv Corona"
                default:
                    return type
            }
        },
        textSv3() {
            const { type } = this
            switch (type) {
                case "like_corona_0":
                    return "Like Sv3"
                case "like_page_corona_0":
                    return "Like Page Sv3"
                case "follow_corona_0":
                    return "Follow Sv3"
                case "share_corona_0":
                    return "Share Sv3"
                case "join_group_corona_0":
                    return "Join Group Sv3"
                default:
                    return type
            }
        },
        sv3SpeedsArray() {
            const matchedObj = this.prices.find(obj => {
                return obj.package_name == this.sv3Type
            })
            if (matchedObj) {
                return matchedObj.speed
            } else return undefined
        }
    },
    watch: {
        router(value) {
            this.returnType(value)
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        },
        async object_id() {
            if (this.object_id.indexOf("facebook.com") > -1) {
                if (this.type === "follow_corona" || this.type === "like_page_corona") {
                    this.object_id = getUserIdFacebookFromUrl(this.object_id)
                } else this.object_id = facebookPostUrlToId(this.object_id)
            }
        },
        type(value) {
            this.getPriceSeedingType(value)
        },
        type_corona() {
            this.getPriceSeedingType(this.type)
        },
        sv3Speed() {
            this.updatePriceBySv3Speed()
        },
        server(value) {
            if (value == "sv3") {
                this.is_warranty = false
                this.getPriceSeedingType(value)
            } else {
                this.getPriceSeedingType(this.type)
            }
        }
    },
    async created() {
        this.returnType(this.$route.path)
        this.getSv3Price()
        this.getPriceSeedingType(this.type)
        this.time_expired = this.increaseDateByNumber(this.time_start, this.date_warranty)
        // this.getCoronaInfo()
    },
    methods: {
        returnType(value) {
            if (value.includes("page")) {
                this.type = "like_page_corona_0"
            } else if (value.includes("follow")) {
                this.type = "follow_corona_0"
            } else if (value.includes("share")) {
                this.type = "share_corona_0"
            } else if (value.includes("join-group")) {
                this.type = "join_group_corona_0"
            } else this.type = "like_corona_0"
            this.type_default = this.type
            this.is_warranty = false
        },
        async getCoronaInfo() {
            let data = await getCoronaInfo()
            if (data.success && data.status === 200 && data.data) {
                this.counter_today = data.data.counter_today
                this.max_today = data.data.max_today
            }
        },
        async checkIDPage() {
            if (!this.object_id || !this.facebook_id) {
                this.$swal("Thông báo", "Hãy làm đúng yêu cầu các bước trước khi kiểm tra!", "error")
                return
            }
            let postData = {
                object_id: this.object_id,
                fb_id: this.facebook_id
            }
            let data = await checkLikePage(postData)
            if (data.status === 200 && data.success) {
                this.checkedId = true
                await this.$swal("Thông báo", "Fanpage có thể tăng Like, hãy thực hiện các bước tiếp theo", "success")
            } else {
                await this.$swal("Thông báo", catchError(data), "error")
            }
        },
        updateChosenSpeed: function(value) {
            this.sv3Speed = value
        },
        updatePriceBySv3Speed() {
            if (this.sv3SpeedsArray.length < 1) {
                return
            }
            const matchedSpeedObj = this.sv3SpeedsArray.find(obj => {
                return obj.speed == this.sv3Speed
            })
            if (matchedSpeedObj) {
                this.price_default = matchedSpeedObj.price
                this.sv3Price = matchedSpeedObj.price
                this.price = matchedSpeedObj.price
            }
        },
        order: async function() {
            if (this.price >= 2 * this.price_default) {
                this.$swal(
                    "Thông báo",
                    "Giá bạn đang chọn cao hơn giá bình thường nhiều lần, vui lòng chỉnh lại giá!",
                    "error"
                )
                return
            }
            if ((this.type === "comment" || this.type === "review") && this.list_messages.length < 1) {
                this.$toastr.error(`Vui lòng nhập nội dung cần ${this.type === "comment" ? "bình luận" : "Review"}`)
                return
            }
            if (this.type === "comment" || this.type === "review") {
                if (
                    this.list_messages.length > 1 &&
                    this.list_messages
                        .toLowerCase()
                        .match(
                            /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                        )
                ) {
                    this.$swal(
                        "Lỗi",
                        `Nội dung ${
                            this.type === "comment" ? "bình luận" : "Review"
                        } của bạn vi phạm tiêu chuẩn cộng đồng, hãy nhập lại nội dung cần bình luận!`,
                        "error"
                    )
                    return
                }
                let arrayMessages = this.list_messages.match(/.+/g)
                for (let index = 0; index < arrayMessages.length; index++) {
                    if (arrayMessages[index].length < 25 && this.type === "review") {
                        this.$swal("Lỗi", "Nội dung Review thứ " + (index + 1) + " tối thiểu phải 25 ký tự!", "error")
                        return
                    } else {
                        let checkIcon = arrayMessages[index].match(/[|\\/~^:;?!&%$@*+]/)
                        if (checkIcon) {
                            this.$swal({
                                title: "Lỗi",
                                html: `Nội dung ${this.type === "comment" ? "bình luận" : "Review"} thứ ${index +
                                    1} không được chứa ký tự đặc biệt: <span class="red bold">${checkIcon[0]}</span>`,
                                type: "error",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "OK"
                            })
                            return
                        }
                    }
                }
            }
            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${this.type_default.toUpperCase()} với giá: ${
                    this.priceSingle
                } ${this.currency}/1 ${this.type_default.toUpperCase()} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        orderConfirm: async function() {
            var postData = {
                link: "https://www.facebook.com/" + this.object_id,
                //type: type,
                object_id: this.object_id,
                quantity: this.quantity,
                prices: this.price * this.quantity,
                time_expired: this.time_expired,
                is_warranty: this.is_warranty,
                notes: this.notes
            }
            if (this.server === "sv3") {
                postData.type = this.sv3Type
                postData.speed = this.sv3Speed
            } else {
                let type = this.type_corona.length > 0 ? this.type + "_" + this.type_corona : this.type
                postData.type = type
            }
            var data = {}
            if (this.server === "sv3") {
                data = await sv3Api.createOrder(postData)
            } else {
                data = await orderSeedingCorona(postData)
            }
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
            this.$store.dispatch("USER_GET_INFO")
        },
        getPriceSeedingType: function(type) {
            if (type == "sv3") {
                this.server = "sv3"
                let price = this.prices.find(price => price.package_name === this.sv3Type)
                if (price) {
                    const speedDefault = price.speed.find(item => {
                        return item.default === 1
                    })
                    this.sv3Speed = speedDefault.speed
                    this.price_default = speedDefault.price
                    this.price = speedDefault.price
                    this.sv3Price = speedDefault.price
                }
            } else {
                this.server = "corona"
                type = this.type_corona.length > 0 ? type + "_" + this.type_corona : type
                let price = this.prices.find(price => price.package_name == "seeding_by_workers_" + type)
                if (price) {
                    this.price_default = price.price
                    this.priceCorona = price.price
                    this.price = price.price
                } else {
                    this.server = "sv3"
                }
                let priceSv3 = this.prices.find(price => price.package_name === this.sv3Type)
                if (priceSv3) {
                    const speedDefault = priceSv3.speed.find(item => {
                        return item.default === 1
                    })
                    if (!price) {
                        this.price_default = speedDefault.price
                        this.priceCorona = speedDefault.price
                        this.price = speedDefault.price
                    }
                    this.sv3Price = speedDefault.price
                }
            }
        },
        getSv3Price(onload = true) {
            if (onload) {
                const matched = this.sv3SpeedsArray.find(obj => {
                    return obj.speed == "low"
                })
                if (matched) {
                    this.sv3Price = matched.price
                }
            } else {
                const matched = this.sv3SpeedsArray.find(obj => {
                    return obj.package_name == this.sv3Type
                })
                if (matched) {
                    this.sv3Price = matched.price
                }
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = regex[1]
                        return true
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = data.data.id
                        return true
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
